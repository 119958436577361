.Container {
  display: flex;
}

.Input {
  flex: 1 1 auto;
  font-size: calc(8px + 1vmin);
  line-height: 1em;
  padding: 1rem 0.5rem;
}

.Button {
  flex: 0 0 auto;
  font-size: calc(8px + 0.5vmin);
  line-height: 1em;
  padding: 1rem;
  font-weight: 500;
  background: none;
  border: 2px solid grey;
  border-radius: 3px;
  color: white;
  letter-spacing: 1px;
}
